<template>
  <div v-loading="uploadImgLoading" class="editor_container">
    <div :id="customDivId">
      <script
        :id="customId"
        name="content"
        type="text/plain"
        :style="styleConfig"
      ></script>
      <!-- <fileUpload @uploadCompleteOSS="upload" :autoUpload="true">
        <el-button :id="customUploadId" style="display: none"
          >上传文件</el-button
        >
      </fileUpload> -->
    </div>
  </div>
</template>

<script>
import { randomString } from "~/baseUtils";
import fileUpload from "./fileUpload.vue";
export default {
  components: { fileUpload },
  name: "umEditor",
  props: {
    styleConfig: {
      type: Object,
      default: () => {
        return {
          width: "100%",
          height: "350px",
        };
      },
    },
  },
  data() {
    return {
      _UM: null,
      currentImgData: null,
      uploadImgLoading: false,
      customId: randomString(16),
      customUploadId: randomString(18),
      customDivId: randomString(24),
    };
  },
  mounted() {
    let _self = this;
    this._UM = UM.getEditor(this.customId, { autoFloatEnabled: false });
    $(`#${this.customDivId} .edui-btn-image`).click(() => {
      document.getElementById(`${_self.customUploadId}`).click();
      return false;
    });
  },
  destroyed() {
    this._UM.destroy();
  },
  methods: {
    upload(e) {
      let img = `<img src=${e.url}>`;
      this._UM.execCommand("insertHtml", img);
    },
    getContent() {
      //获取内容
      return this._UM.getContent();
    },
    setContent(value) {
      //写入内容
      this._UM.setContent(value);
    },
    insertHtml(value) {
      //插入html
      this._UM.execCommand("insertHtml", value);
    },
  },
};
</script>

<style lang="less" >
.edui-btn-toolbar .edui-btn {
  padding: 0px;
}
.edui-btn.edui-hover {
  border: 0 none !important;
}
.edui-btn-toolbar .edui-combobox {
  border: 0 none;
}
.edui-btn-toolbar .edui-btn.edui-active {
  border: 0 none;
}
.editor_container img {
  box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  -webkit-box-sizing: content-box !important;
}
.editor_container .edui-scale,
.editor_container .edui-scale * {
  box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  -webkit-box-sizing: content-box !important;
}

</style>
