<template>
  <div>
    <el-row>
      <el-col :span="companyFileAndInvoiceNum == 2 ? 24 : 10"
              v-if="companyFileAndInvoiceNum == 2 || companyFileAndInvoiceNum == 3">
        <el-form :model="form" ref="ruleForm" :rules="rules" style="margin-top: 20px">
          <el-form-item
              size="mini"
              label="开票抬头"
              label-width="150px"
              required
              :show-message="false"
              prop="title"
          >
            <el-input v-model="form.title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item
              size="mini"
              label="纳税人识别号"
              label-width="150px"
              required
              :show-message="false"
              prop="itin"
          >
            <el-input v-model="form.itin" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item
              size="mini"
              label="发票地址"
              label-width="150px"
              required
              :show-message="false"
              prop="address"
          >
            <el-input v-model="form.address" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item
              size="mini"
              label="电话"
              label-width="150px"
              required
              :show-message="false"
              prop="phone"
          >
            <el-input v-model="form.phone" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item
              size="mini"
              label="开票银行"
              label-width="150px"
              required
              :show-message="false"
              prop="bank"
          >
            <div class="myInput" @click="handle_bankInput_click" :title="form.bank|show_bank_msg" >{{ form.bank|show_bank_msg }}</div>
            <!-- <el-input v-model="form.bank" autocomplete="off"></el-input> -->
          </el-form-item>
          <el-form-item
              size="mini"
              label="邮箱"
              label-width="150px"
              required
              :show-message="false"
              prop="email"
          >
            <el-input v-model="form.email" autocomplete="off"></el-input>
          
          </el-form-item>
          <el-form-item
            label="纸质发票收件地址"
            label-width="150px"
            required
            :show-message="false"
            prop="consignee_address"
          >
            <el-input size='small' v-model="form.consignee_address" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item
            label="发票接收人"
            label-width="160px"
            required
            :show-message="false"
            prop="recipients"
          >
            <el-input size='small' v-model="form.recipients" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item
            label="发票收件电话"
            label-width="160px"
            required
            :show-message="false"
            prop="consignee_phone"
          >
            <el-input size='small' v-model="form.consignee_phone" autocomplete="off"></el-input>
          </el-form-item>

        </el-form>
      </el-col>
      <el-col :span="companyFileAndInvoiceNum == 1 ? 24 : 12" :offset="companyFileAndInvoiceNum == 3 ? 2 : 0"
              v-if="companyFileAndInvoiceNum == 1 || companyFileAndInvoiceNum == 3">
        <certificate
            ref="certificate"
            :generationBinding="generationBinding"
            :bind_company_id="bind_company_id"
            @uploadCert="uploadCertImg"
        ></certificate>
      </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose" size="small">取 消</el-button>
      <el-button type="primary" @click="submitForm" size="small">确 定
      </el-button>
    </div>
    <el-dialog
      width="960px"
      title="银行信息"
      :visible.sync="bank_dialog_show"
      append-to-body>
      <div>
        <div style="text-align:right">
            <el-button type="primary" size="small" @click="add_one_bank">添加开户银行</el-button>
        </div>
        <div class="bank_box" v-if="form.bank">
            <div>
              <p class="t1">币种</p>
              <p class="t2">银行名称</p>
              <p class="t3">银行账号</p>
              <p class="t4">是否默认</p>
              <p class="t5"></p>
            </div>
            <div v-for="(item,index) in form.bank" :key="index" style="padding-bottom:10px">
              <div class="t1">
                  <el-select  v-model="form.bank[index].code" size='mini' placeholder="请选择" style="width:90px" >
                    <el-option
                      v-for="(it,i) in codeArr"
                      :key="i"
                      :label="it.code"
                      :value="it.code">
                    </el-option>
                  </el-select>
              </div>
              <div class="t2">
                <el-input  v-model="form.bank[index].bank_name" size="mini" placeholder="需包含支行信息"></el-input>
              </div>
              <div class="t3">
                <el-input   v-model="form.bank[index].bank_num" size="mini" placeholder=""></el-input>
              </div>
              <div class="t4" style="padding-left:10px">
                <el-checkbox v-model="form.bank[index].is_default" :disabled="form.bank[index].is_default" @change="checked=>is_default_change(checked,index)"></el-checkbox>
              </div>
              <div class="t5">
                <el-button type="text" size="mini" :disabled="form.bank.length==1||form.bank[index].is_default" @click="bank_item_cancle(index)">删除</el-button>
              </div>
            </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="bank_dialog_show=false" size="small">取 消</el-button>
        <el-button type="primary" @click="bank_sure" size="small">确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import certificate from "~bac/components/company/basic/certificate";

export default {
  name: "addCompanyInfo",
  props: {
    companyFileAndInvoiceNum: {
      type: Number | String,
      default: 1
    },
    generationBinding: {
      type: Boolean,
      default: false
    },
    bind_company_id: {
      type: String | Number,
      default: ''
    }
  },
  components: {certificate},
  data() {
    return {
      codeArr:[],
      bank_dialog_show:false,
      uploadCert: {
        url: '',
        certificate_id: ''
      },
      form: {
        title: "",
        itin: "",
        address: "",
        phone: "",
        bank:[
          {
            code:"",
            bank_name:"",
            bank_num:"",
            is_default:true,
          }
        ],
        email: "",
        recipients:"",//发票接收人
        consignee_address:"",//纸质发票收件地址
        consignee_phone:"",//发票接受电话
      },
      rules: {},
    }
  },
  mounted(){
    this.getCodeList()
  },
  filters:{
    show_bank_msg(arr){
      if(typeof(arr)=='string'){
        arr=JSON.parse(arr)
      }
      let obj=arr.find(item=>{
        return item.is_default==true
      })
      if(obj.code && obj.bank_name && obj.bank_num){
        return `${obj.code}:${obj.bank_name}:${obj.bank_num}`
      }else{
        return ""
      }
    }
  },
  methods: {
    uploadCertImg(url, data) {
      this.uploadCert.url = url;
      this.uploadCert.certificate_id = data.length > 0 && data[0].id;
    },
    async getCodeList(){
      let res=await this.$store.dispatch("API_bookingSpace/getcurrencyList")
      this.codeArr=res.data
    },
    async confirm() {
      let uploadParams = {
        company_id: this.generationBinding ? this.bind_company_id: this.USER_INFO.company_id,
        url: this.uploadCert.url,
        certificate_type: 0,
      };
      uploadParams.certificate_id = this.uploadCert.id;
      let result = await Promise.all([
        this.$store.dispatch(
            "baseConsole/company_uploadQualification",
            uploadParams
        ),
      ]);
      if (result) {
      }
    },
    // 验证开票银行不为为空
    bankMsg_empty(){
      let not_empty=false
      let emp=this.form.bank.find(item=>{
        return !item.code || !item.bank_name || !item.bank_num
      })
      if(emp){
        not_empty=true
      }
      return not_empty
    },
    //  银行弹框确定
    bank_sure(){
      if(this.bankMsg_empty()){
        this.$message.warning('银行信息不能为空')
        return
      }else{
        this.bank_dialog_show=false
      }
    },
    // 打开银行信息弹框
    handle_bankInput_click(){
      this.bank_dialog_show=true
    },
    // 添加一条银行信息
    add_one_bank(){
      this.form.bank.push({
        code:"",
        bank_name:"",
        bank_num:"",
        is_default:false,
      })
    },
    // 修改银行信息的默认
    is_default_change(checked,index){
      this.form.bank.map((item,i)=>{
        if(i==index){
          item.is_default=true
        }else{
          item.is_default=false
        }
        return item
      })
    },
    // 删除一条银行信息
    bank_item_cancle(index){
      this.form.bank.splice(index,1)
    },
    async submitForm() {
      if (this.companyFileAndInvoiceNum == 1) {
        if (this.uploadCert.url == '') return this.$message.warning('请上传企业证书')
        let uploadParams = {
          company_id: this.generationBinding ? this.bind_company_id: this.USER_INFO.company_id,
          url: this.uploadCert.url,
          certificate_type: 0,
        };
        uploadParams.certificate_id = this.uploadCert.id;
        let data = await this.$store.dispatch(
            "baseConsole/company_uploadQualification",
            uploadParams
        );
        if (data.success) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.$emit('upOK')
        }
      }
      if (this.companyFileAndInvoiceNum == 3) {
        this.$refs.ruleForm.validate(async (valid) => {
          if (valid) {
            if (this.uploadCert.url == '') return this.$message.warning('请上传企业证书')

            if(this.bankMsg_empty()){
              this.$message.warning('银行信息不能为空')
              this.bank_dialog_show=true
              return
            }
            let params = this.form;
           
            params.company_id = this.generationBinding ? this.bind_company_id: this.USER_INFO.company_id;
            let uploadParams = {
              company_id: this.generationBinding ? this.bind_company_id: this.USER_INFO.company_id,
              url: this.uploadCert.url,
              certificate_type: 0,
            };
            uploadParams.certificate_id = this.uploadCert.id;
            let bk=JSON.parse(JSON.stringify(this.form.bank))
            params.bank=JSON.stringify(bk)
            try {
              let result = await Promise.all([
                this.$store.dispatch("baseConsole/company_uploadQualification", uploadParams),
                this.$store.dispatch("baseConsole/addinvoiceMsg", params),
              ]);
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.$emit('upOK')

            } catch (e) {
              this.$seTip();
            }
          }
        })
      }
      if (this.companyFileAndInvoiceNum == 2) {
        this.$refs.ruleForm.validate(async (valid) => {
          if (valid) {
            let params = this.form;
            params.bank=JSON.stringify(this.form.bank)
            params.company_id = this.generationBinding ? this.bind_company_id: this.USER_INFO.company_id;
            let data = await this.$store.dispatch(
                "baseConsole/addinvoiceMsg",
                params
            );
            if (data.success) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.$emit('upOK')
            }
          }
        })
      }
    },
    handleClose() {
      this.$emit('handleClose')
    }
  },
}
</script>

<style scoped lang="less">
  .myInput{
    min-width:234.5px;
    height: 28px;
    line-height: 28px;
    line-height: 28px;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    padding: 0px 15px;
    cursor: pointer;
    color: #6f6f6f;
    font-size: 7px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
  .bank_box{
    padding-left:160px; 
    padding-bottom:20px;
    >div{
      display: flex;
      .t1{
        width: 100px;
        padding-right: 10px;
      }
      .t2{
        width: 200px;
        padding-right: 10px;
      }
      .t3{
        width: 200px;
        padding-right: 10px;
      }
      .t4{
        width: 100px;
        padding-right: 10px;
      }
      .t5{
        width: 100px;
        padding-right: 10px;
      }
    }
    >div>p{
      padding-bottom: 10px;
    }
    >div>div{
      display: flex;
      align-items: center;
    }
  }
</style>